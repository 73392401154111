import { default as ai_45form_45builder18OWIPI9oqMeta } from "/vercel/path0/pages/ai-form-builder.vue?macro=true";
import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as registerUZDCsj62s6Meta } from "/vercel/path0/pages/auth/register.vue?macro=true";
import { default as editx3bf3xTVV7Meta } from "/vercel/path0/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexYOX8Dy9HPcMeta } from "/vercel/path0/pages/forms/[slug]/index.vue?macro=true";
import { default as indexrmMMcMS7XiMeta } from "/vercel/path0/pages/forms/[slug]/show/index.vue?macro=true";
import { default as shareuHrFODfewjMeta } from "/vercel/path0/pages/forms/[slug]/show/share.vue?macro=true";
import { default as stats7oe2kSFHhFMeta } from "/vercel/path0/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissions6ZU1rODeSNMeta } from "/vercel/path0/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showEnTDMdvNL0Meta } from "/vercel/path0/pages/forms/[slug]/show.vue?macro=true";
import { default as guestxOggIrw2KLMeta } from "/vercel/path0/pages/forms/create/guest.vue?macro=true";
import { default as indexwDnG7wIxKNMeta } from "/vercel/path0/pages/forms/create/index.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index27xh5qC0QjxMeta } from "/vercel/path0/pages/index2.vue?macro=true";
import { default as emailrRhNFCbth2Meta } from "/vercel/path0/pages/password/email.vue?macro=true";
import { default as _91token_93XzTxypJMaqMeta } from "/vercel/path0/pages/password/reset/[token].vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as accountujSiEcXIUQMeta } from "/vercel/path0/pages/settings/account.vue?macro=true";
import { default as admin19Fntmq7QpMeta } from "/vercel/path0/pages/settings/admin.vue?macro=true";
import { default as billinglb9Lga5AxiMeta } from "/vercel/path0/pages/settings/billing.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as passwordrflUBBme5TMeta } from "/vercel/path0/pages/settings/password.vue?macro=true";
import { default as profile3g7qqlrA4oMeta } from "/vercel/path0/pages/settings/profile.vue?macro=true";
import { default as workspaceOm0WfKKmsnMeta } from "/vercel/path0/pages/settings/workspace.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as erroriOhwtHMq83Meta } from "/vercel/path0/pages/subscriptions/error.vue?macro=true";
import { default as successeUTmwt5mwdMeta } from "/vercel/path0/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93mkdqCF4T7zMeta } from "/vercel/path0/pages/templates/[slug].vue?macro=true";
import { default as indexGPrbsgoqBjMeta } from "/vercel/path0/pages/templates/index.vue?macro=true";
import { default as _91slug_93bqpKSvOLICMeta } from "/vercel/path0/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesfkkhhFmhYgMeta } from "/vercel/path0/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93wUQfWtlXkBMeta } from "/vercel/path0/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsvAyA1KQpqLMeta } from "/vercel/path0/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: ai_45form_45builder18OWIPI9oqMeta?.name ?? "ai-form-builder",
    path: ai_45form_45builder18OWIPI9oqMeta?.path ?? "/ai-form-builder",
    meta: ai_45form_45builder18OWIPI9oqMeta || {},
    alias: ai_45form_45builder18OWIPI9oqMeta?.alias || [],
    redirect: ai_45form_45builder18OWIPI9oqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: loginILQdBdZzRsMeta?.name ?? "auth-login",
    path: loginILQdBdZzRsMeta?.path ?? "/auth/login",
    meta: loginILQdBdZzRsMeta || {},
    alias: loginILQdBdZzRsMeta?.alias || [],
    redirect: loginILQdBdZzRsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registerUZDCsj62s6Meta?.name ?? "auth-register",
    path: registerUZDCsj62s6Meta?.path ?? "/auth/register",
    meta: registerUZDCsj62s6Meta || {},
    alias: registerUZDCsj62s6Meta?.alias || [],
    redirect: registerUZDCsj62s6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: editx3bf3xTVV7Meta?.name ?? "forms-slug-edit",
    path: editx3bf3xTVV7Meta?.path ?? "/forms/:slug()/edit",
    meta: editx3bf3xTVV7Meta || {},
    alias: editx3bf3xTVV7Meta?.alias || [],
    redirect: editx3bf3xTVV7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexYOX8Dy9HPcMeta?.name ?? "forms-slug",
    path: indexYOX8Dy9HPcMeta?.path ?? "/forms/:slug()",
    meta: indexYOX8Dy9HPcMeta || {},
    alias: indexYOX8Dy9HPcMeta?.alias || [],
    redirect: indexYOX8Dy9HPcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    path: showEnTDMdvNL0Meta?.path ?? "/forms/:slug()/show",
    children: [
  {
    name: indexrmMMcMS7XiMeta?.name ?? "forms-slug-show",
    path: indexrmMMcMS7XiMeta?.path ?? "",
    meta: indexrmMMcMS7XiMeta || {},
    alias: indexrmMMcMS7XiMeta?.alias || [],
    redirect: indexrmMMcMS7XiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareuHrFODfewjMeta?.name ?? "forms-slug-show-share",
    path: shareuHrFODfewjMeta?.path ?? "share",
    meta: shareuHrFODfewjMeta || {},
    alias: shareuHrFODfewjMeta?.alias || [],
    redirect: shareuHrFODfewjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: stats7oe2kSFHhFMeta?.name ?? "forms-slug-show-stats",
    path: stats7oe2kSFHhFMeta?.path ?? "stats",
    meta: stats7oe2kSFHhFMeta || {},
    alias: stats7oe2kSFHhFMeta?.alias || [],
    redirect: stats7oe2kSFHhFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: submissions6ZU1rODeSNMeta?.name ?? "forms-slug-show-submissions",
    path: submissions6ZU1rODeSNMeta?.path ?? "submissions",
    meta: submissions6ZU1rODeSNMeta || {},
    alias: submissions6ZU1rODeSNMeta?.alias || [],
    redirect: submissions6ZU1rODeSNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
],
    name: showEnTDMdvNL0Meta?.name ?? undefined,
    meta: showEnTDMdvNL0Meta || {},
    alias: showEnTDMdvNL0Meta?.alias || [],
    redirect: showEnTDMdvNL0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/[slug]/show.vue").then(m => m.default || m)
  },
  {
    name: guestxOggIrw2KLMeta?.name ?? "forms-create-guest",
    path: guestxOggIrw2KLMeta?.path ?? "/forms/create/guest",
    meta: guestxOggIrw2KLMeta || {},
    alias: guestxOggIrw2KLMeta?.alias || [],
    redirect: guestxOggIrw2KLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: indexwDnG7wIxKNMeta?.name ?? "forms-create",
    path: indexwDnG7wIxKNMeta?.path ?? "/forms/create",
    meta: indexwDnG7wIxKNMeta || {},
    alias: indexwDnG7wIxKNMeta?.alias || [],
    redirect: indexwDnG7wIxKNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: homeaAKJ71q3qIMeta?.name ?? "home",
    path: homeaAKJ71q3qIMeta?.path ?? "/home",
    meta: homeaAKJ71q3qIMeta || {},
    alias: homeaAKJ71q3qIMeta?.alias || [],
    redirect: homeaAKJ71q3qIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index27xh5qC0QjxMeta?.name ?? "index2",
    path: index27xh5qC0QjxMeta?.path ?? "/index2",
    meta: index27xh5qC0QjxMeta || {},
    alias: index27xh5qC0QjxMeta?.alias || [],
    redirect: index27xh5qC0QjxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index2.vue").then(m => m.default || m)
  },
  {
    name: emailrRhNFCbth2Meta?.name ?? "password-email",
    path: emailrRhNFCbth2Meta?.path ?? "/password/email",
    meta: emailrRhNFCbth2Meta || {},
    alias: emailrRhNFCbth2Meta?.alias || [],
    redirect: emailrRhNFCbth2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: _91token_93XzTxypJMaqMeta?.name ?? "password-reset-token",
    path: _91token_93XzTxypJMaqMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93XzTxypJMaqMeta || {},
    alias: _91token_93XzTxypJMaqMeta?.alias || [],
    redirect: _91token_93XzTxypJMaqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing",
    path: pricingIYNdvfoSO6Meta?.path ?? "/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    path: settingsyZZu4Rd1aAMeta?.path ?? "/settings",
    children: [
  {
    name: accountujSiEcXIUQMeta?.name ?? "settings-account",
    path: accountujSiEcXIUQMeta?.path ?? "account",
    meta: accountujSiEcXIUQMeta || {},
    alias: accountujSiEcXIUQMeta?.alias || [],
    redirect: accountujSiEcXIUQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: admin19Fntmq7QpMeta?.name ?? "settings-admin",
    path: admin19Fntmq7QpMeta?.path ?? "admin",
    meta: admin19Fntmq7QpMeta || {},
    alias: admin19Fntmq7QpMeta?.alias || [],
    redirect: admin19Fntmq7QpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billinglb9Lga5AxiMeta?.name ?? "settings-billing",
    path: billinglb9Lga5AxiMeta?.path ?? "billing",
    meta: billinglb9Lga5AxiMeta || {},
    alias: billinglb9Lga5AxiMeta?.alias || [],
    redirect: billinglb9Lga5AxiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: indexKIRFIU1ID4Meta?.name ?? "settings",
    path: indexKIRFIU1ID4Meta?.path ?? "",
    meta: indexKIRFIU1ID4Meta || {},
    alias: indexKIRFIU1ID4Meta?.alias || [],
    redirect: indexKIRFIU1ID4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordrflUBBme5TMeta?.name ?? "settings-password",
    path: passwordrflUBBme5TMeta?.path ?? "password",
    meta: passwordrflUBBme5TMeta || {},
    alias: passwordrflUBBme5TMeta?.alias || [],
    redirect: passwordrflUBBme5TMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profile3g7qqlrA4oMeta?.name ?? "settings-profile",
    path: profile3g7qqlrA4oMeta?.path ?? "profile",
    meta: profile3g7qqlrA4oMeta || {},
    alias: profile3g7qqlrA4oMeta?.alias || [],
    redirect: profile3g7qqlrA4oMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspaceOm0WfKKmsnMeta?.name ?? "settings-workspace",
    path: workspaceOm0WfKKmsnMeta?.path ?? "workspace",
    meta: workspaceOm0WfKKmsnMeta || {},
    alias: workspaceOm0WfKKmsnMeta?.alias || [],
    redirect: workspaceOm0WfKKmsnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings/workspace.vue").then(m => m.default || m)
  }
],
    name: settingsyZZu4Rd1aAMeta?.name ?? undefined,
    meta: settingsyZZu4Rd1aAMeta || {},
    alias: settingsyZZu4Rd1aAMeta?.alias || [],
    redirect: settingsyZZu4Rd1aAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: erroriOhwtHMq83Meta?.name ?? "subscriptions-error",
    path: erroriOhwtHMq83Meta?.path ?? "/subscriptions/error",
    meta: erroriOhwtHMq83Meta || {},
    alias: erroriOhwtHMq83Meta?.alias || [],
    redirect: erroriOhwtHMq83Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successeUTmwt5mwdMeta?.name ?? "subscriptions-success",
    path: successeUTmwt5mwdMeta?.path ?? "/subscriptions/success",
    meta: successeUTmwt5mwdMeta || {},
    alias: successeUTmwt5mwdMeta?.alias || [],
    redirect: successeUTmwt5mwdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkdqCF4T7zMeta?.name ?? "templates-slug",
    path: _91slug_93mkdqCF4T7zMeta?.path ?? "/templates/:slug()",
    meta: _91slug_93mkdqCF4T7zMeta || {},
    alias: _91slug_93mkdqCF4T7zMeta?.alias || [],
    redirect: _91slug_93mkdqCF4T7zMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexGPrbsgoqBjMeta?.name ?? "templates",
    path: indexGPrbsgoqBjMeta?.path ?? "/templates",
    meta: indexGPrbsgoqBjMeta || {},
    alias: indexGPrbsgoqBjMeta?.alias || [],
    redirect: indexGPrbsgoqBjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bqpKSvOLICMeta?.name ?? "templates-industries-slug",
    path: _91slug_93bqpKSvOLICMeta?.path ?? "/templates/industries/:slug()",
    meta: _91slug_93bqpKSvOLICMeta || {},
    alias: _91slug_93bqpKSvOLICMeta?.alias || [],
    redirect: _91slug_93bqpKSvOLICMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45templatesfkkhhFmhYgMeta?.name ?? "templates-my-templates",
    path: my_45templatesfkkhhFmhYgMeta?.path ?? "/templates/my-templates",
    meta: my_45templatesfkkhhFmhYgMeta || {},
    alias: my_45templatesfkkhhFmhYgMeta?.alias || [],
    redirect: my_45templatesfkkhhFmhYgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUQfWtlXkBMeta?.name ?? "templates-types-slug",
    path: _91slug_93wUQfWtlXkBMeta?.path ?? "/templates/types/:slug()",
    meta: _91slug_93wUQfWtlXkBMeta || {},
    alias: _91slug_93wUQfWtlXkBMeta?.alias || [],
    redirect: _91slug_93wUQfWtlXkBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsvAyA1KQpqLMeta?.name ?? "terms-conditions",
    path: terms_45conditionsvAyA1KQpqLMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsvAyA1KQpqLMeta || {},
    alias: terms_45conditionsvAyA1KQpqLMeta?.alias || [],
    redirect: terms_45conditionsvAyA1KQpqLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-conditions.vue").then(m => m.default || m)
  }
]